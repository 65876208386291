import { template as template_ebf0a354cc89475f862aa065df80481e } from "@ember/template-compiler";
const FKLabel = template_ebf0a354cc89475f862aa065df80481e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
