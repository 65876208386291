import { template as template_456d8cb080df496f8934f6a7e0b65b18 } from "@ember/template-compiler";
const WelcomeHeader = template_456d8cb080df496f8934f6a7e0b65b18(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
