import { template as template_65eb28831dd44d09a9f3b4f5fb14ce93 } from "@ember/template-compiler";
const EmptyState = template_65eb28831dd44d09a9f3b4f5fb14ce93(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
